// App.js
import React from 'react';
import Home from './Home';  // Ensure this path is correct
import './App.css';  // Optional: For any global styles

function App() {
  return (
    <div className="App">
      <Home />  {/* This will render your Home component */}
    </div>
  );
}

export default App;
