import React, { useState, useEffect } from 'react';
import './Home.css';

const Home = () => {
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const [name, setName] = useState('');
  const [contractAddress, setContractAddress] = useState('7fzpwVhvpfzM5LDC6PE9wiJxMArAjtYCvLN79Epopump'); // Example contract address
  const [isNameSubmitted, setIsNameSubmitted] = useState(false);
  const [secretRevealed, setSecretRevealed] = useState(false);
  const [soundPlaying, setSoundPlaying] = useState(false); // Track if sound is playing
  const [passwordVisible, setPasswordVisible] = useState(false); // Track password visibility
  const [copied, setCopied] = useState(false); // Track if contract address has been copied
  const [xVisible, setXVisible] = useState(true); // Track visibility of the "X" icon

  // Audio for the mysterious background sound
  const audio = new Audio('/sounds/mysterious-sound.mp3');
  audio.loop = true; // Loop the sound

  // Start or stop the sound based on soundPlaying state
  useEffect(() => {
    if (soundPlaying) {
      audio.play().catch((err) => console.log('Error playing audio:', err));
    } else {
      audio.pause();
    }

    return () => {
      audio.pause(); // Clean up audio on component unmount
    };
  }, [soundPlaying]);

  // Handle name input change
  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  // Handle password input change
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  // Handle name submission
  const handleNameSubmit = (e) => {
    e.preventDefault();
    setIsNameSubmitted(true);
    setSoundPlaying(true); // Play sound after name submission
  };

  // Handle password submission
  const handlePasswordSubmit = (e) => {
    e.preventDefault();
    if (password === 'enigra') {
      setMessage(`You're inside...`);
      setSecretRevealed(true); // Reveal secret message after correct password
    } else {
      setMessage('Incorrect password. Try again...');
    }
  };

  // Toggle sound
  const toggleSound = () => {
    setSoundPlaying(!soundPlaying);
  };

  // Toggle password visibility
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  // Copy contract address to clipboard
  const copyToClipboard = () => {
    navigator.clipboard.writeText(contractAddress);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000); // Reset copied state after 2 seconds
  };

  // Handle "X" click to redirect to Twitter in a new tab
  const handleXClick = () => {
    window.open("https://twitter.com/enigrasol", "_blank"); // Open Twitter in a new tab
  };

  return (
    <div className="home-container">
      {/* X placed in the top-left corner */}
      {xVisible && (
        <div className="x-section" onClick={handleXClick}>
          <h2>X</h2>
        </div>
      )}

      <div className="content">
        {!isNameSubmitted ? (
          <div className="name-form">
            <h1 className="title">Decode the Future</h1>
            <input
              type="text"
              className="name-input"
              placeholder="Enter your name..."
              value={name}
              onChange={handleNameChange}
            />
            <button className="name-submit-btn" onClick={handleNameSubmit}>
              Enter
            </button>
          </div>
        ) : (
          // After name submission, display password form or nothing if password is correct
          !secretRevealed && (
            <div className="password-form">
              <h1 className="title">Welcome {name}</h1>
              <form onSubmit={handlePasswordSubmit} className="password-form-inner">
                <input
                  type={passwordVisible ? 'text' : 'password'}
                  className="password-input"
                  placeholder="Enter password..."
                  value={password}
                  onChange={handlePasswordChange}
                />
                <button
                  className="password-submit-btn"
                  type="submit"
                >
                  Submit
                </button>
                <button
                  type="button"
                  className="toggle-password-btn"
                  onClick={togglePasswordVisibility}
                >
                  {passwordVisible ? 'Hide' : 'Show'} Password
                </button>
              </form>
              {message && <p className="message">{message}</p>}
            </div>
          )
        )}

        {/* Secret revealed message */}
        {secretRevealed && (
          <div className="secret-path-message">
            <p className="secret-message">You have unlocked the secret path...</p>
          </div>
        )}

        {/* Display contract address after the password is correct */}
        {secretRevealed && (
          <div className="contract-info">
            <h2>Contract Address</h2>
            <input
              type="text"
              className="contract-input"
              value={contractAddress}
              readOnly
            />
            <button className="copy-btn" onClick={copyToClipboard}>
              {copied ? 'Copied!' : 'Copy to Clipboard'}
            </button>
          </div>
        )}

        {/* Sound Control Icon */}
        <div className="sound-toggle" onClick={toggleSound}>
          <img
            src={soundPlaying ? '/icons/sound-on-icon.png' : '/icons/sound-off-icon.png'}
            alt="sound control"
            className="sound-icon"
          />
        </div>

      </div>

      {/* Footer - Copyright */}
      <footer>
        &copy; 2024 Enigra. All rights reserved.
      </footer>
    </div>
  );
};

export default Home;
